import React from "react";

export default function Header() {
  return (
    <>
      <header>
        <h1>
          <s>Andres</s> Andy Rodriguez
        </h1>
      </header>
    </>
  );
}
